import * as Color from 'color';
import { autobind } from 'core-decorators';
import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import { Config } from '../modules/config';
import { Constants } from '../modules/constants';
import { GridGenerator } from '../modules/generator';
import { BoolMultiChooser, CategoryChooser, ImageMultiChooser, MultiChooser } from './Choosers';

export class CloseButton extends React.Component<{ onClick: () => void }, { hover: boolean }> {

    constructor(props) {
        super(props);
        this.state = { hover: false};
    }

    render() {
        return <div style={this.styleIcon()} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} onClick={this.handleClick} ><FontAwesome name={"times"} /></div>

    }

    private styleIcon(): React.CSSProperties {
        const color = new Color(Constants.BACKGROUND_COLOR).darken(this.state.hover ? 0.66 : 0.33).toString();
        return {
            position: "absolute",
            top: "2vmin",
            right: "3vmin",
            fontSize: "6vmin",
            color: color,
            cursor: this.state.hover ? "pointer" : "auto",
        }
    }

    @autobind
    private toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    @autobind
    private handleClick(e) {
        e.stopPropagation();
        this.props.onClick();
    }
}


export interface BingoSettingsScreenProps {
    onClose:() => void;
    onRefresh:() => void;
    onRegenerate:() => void;
}

export class BingoSettingsScreen extends React.Component<BingoSettingsScreenProps, null> {

    constructor(props) {
        super(props);
    }

    render() {
        return <div style={this.styleScreen()} onClick={(e) => {e.stopPropagation()}}>
            <CloseButton onClick={() => this.props.onClose()} />
            <span style={this.styleHeader()}>SETTINGS</span>
            <MultiChooser label="Grid size" initialValue={Config.gridSize.toString()} values={["3", "5", "7", "9"]} onValueChange={this.gridSizeChanged}/>
            <BoolMultiChooser label="Free spot" initialValue={Config.hasFreeSpot ? "true" : "false"} values={["true", "false"]} onValueChange={this.freeSpotChanged}/>
            <BoolMultiChooser label="Category names" initialValue={Config.showCategoryName ? "true" : "false"} values={["true", "false"]} onValueChange={this.categoryNamesChanged}/>
            <ImageMultiChooser label="Marker image" initialValue={Config.doneMarkerImage} values={["smm-block.png", "smm-bobomb.png", "smm-goomba.png", "smm-mushroom.png", "smm-note.png", "smm-redblock.png"]} onValueChange={this.markerImageChanged}/>
            <MultiChooser label="Marker opacity" initialValue={Config.doneMarkerOpacity.toString()} values={["50%", "80%", "100%"]} onValueChange={this.opacityChanged}/>
            <BoolMultiChooser label="Show logo" initialValue={Config.showLogo ? "true" : "false"} values={["true", "false"]} onValueChange={this.showLogoChanged}/>
            <CategoryChooser label="Categories" onValueChange={this.categoriesChanged} />
        </div>
    }

    @autobind
    private gridSizeChanged(value: string) {
        try {
            const newGridSize = parseInt(value, 10);
            Config.gridSize = newGridSize;
            this.props.onRegenerate();
        } catch (e) {
            console.warn(e);
        }
    }
    @autobind
    private opacityChanged(value: string) {
        try {
            Config.doneMarkerOpacity = value;
            this.props.onRefresh();
        } catch (e) {
            console.warn(e);
        }
    }

    @autobind
    private markerImageChanged(value: string) {
        try {
            Config.doneMarkerImage = value;
            this.props.onRefresh();
        } catch (e) {
            console.warn(e);
        }
    }

    @autobind
    private freeSpotChanged(value: string) {
        try {
            const hasFreeSpot = (value === "true");
            Config.hasFreeSpot = hasFreeSpot;
            this.props.onRegenerate();
        } catch (e) {
            console.warn(e);
        }
    }

    @autobind
    private showLogoChanged(value: string) {
        try {
            const showLogo = (value === "true");
            Config.showLogo = showLogo;
            this.props.onRefresh();
        } catch (e) {
            console.warn(e);
        }
    }

    @autobind
    private categoryNamesChanged(value: string) {
        try {
            const showCategoryName = (value === "true");
            Config.showCategoryName = showCategoryName;
            this.props.onRefresh();
        } catch (e) {
            console.warn(e);
        }
    }

    @autobind
    private categoriesChanged(value: string[]) {
        try {
            Config.bannedCategories = value;
            this.props.onRegenerate();
        } catch (e) {
            console.warn(e);
        }
    }

    private styleHeader(): React.CSSProperties {
        return {
            display: "block",
            fontSize: "6vmin",
            textAlign: "center",
            marginBottom: "1vmin"
        }
    }

    private styleScreen(): React.CSSProperties {
        return {
            boxSizing: "border-box",
            padding: "1vmin",
            height: "calc(100vh - 10vmin",
            width: "calc(100vw - 10vmin)",
            marginLeft: "auto",
            fontSize: "3vh",
            marginRight: "auto",
            marginTop: "5vmin",
            backgroundColor: Constants.BACKGROUND_COLOR,
            borderRadius: "2vmin",
            textAlign: "center",
            filter: "drop-shadow(1vmin 1vmin 1vmin #000000)"
        }
    }

}