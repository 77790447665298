import * as React from 'react';
import { autobind } from 'core-decorators';
import { BingoData } from '../data';
import { Constants } from '../modules/constants';
import * as Color from 'color';
import { Config } from '../modules/config';

export interface BingoCellProps {
    cellSize: number;
    task: BingoData.Task;
}

export interface BingoCellState {
    hover: boolean;
    done: boolean;
}

export class BingoDoneMarker extends React.Component<{}, null> {
    constructor(props) {
        super(props);
    }

    render() {
        return <div style={this.styleMarker()}></div>
    }

    private styleMarker(): React.CSSProperties {
        return {
            backgroundImage: `url("img/${Config.doneMarkerImage}")`,
            width: "100%",
            height: "100%",
            opacity: Config.doneMarkerOpacity,
            backgroundSize: "contain",
            position: "absolute",
            top: 0,
            left: 0
        }
    }
}

export class BingoCell extends React.Component<BingoCellProps, BingoCellState> {

    constructor(props) {
        super(props);
        this.state = { hover: false, done: this.props.task.category.id == "FREE" ? true : false };
    }

    render() {
        return <div style={this.styleCell()} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} onClick={this.toggleDone}>
            {Config.showCategoryName ? <span style={this.styleCategoryText()}>{this.props.task.category.name}</span> : null }
            {this.state.done ? <BingoDoneMarker /> : null}
            <span style={this.styleText()}>{this.props.task.name}</span>
        </div>
    }

    private styleCell(): React.CSSProperties {
        const CELL_BORDER_PERCENTAGE = 0.05;
        const cellBorderAmount = this.props.cellSize * CELL_BORDER_PERCENTAGE;
        const actualCellSize = this.props.cellSize - 2 * (cellBorderAmount);
        const backgroundColor = new Color(this.getCellBackgroundColor()).darken(this.state.hover ? 0.1 : 0).toString();
        return {
            display: "block",
            boxSizing: "border-box",
            height: "100%",
            width: "100%",
            lineHeight: `${actualCellSize}vmin`,
            textAlign: "center",
            position: "relative",
            backgroundColor: backgroundColor,
            padding: `${cellBorderAmount}vmin`,
            borderBottom: "solid 2px #000000",
            borderRight: "solid 2px #000000",
            cursor: this.state.hover ? "pointer" : "auto"
        }
    }

    private styleCategoryText(): React.CSSProperties {
        const lineHeight = this.props.cellSize / 8;
        const color = new Color(this.getCellBackgroundColor()).darken(this.state.hover ? 0.4 : 0.2).toString();
        return {
            display: "inline-block",
            position: "absolute",
            top: `${lineHeight / 4}vmin`,
            left: `${lineHeight / 4}vmin`,
            lineHeight: `${lineHeight}vmin`,
            fontSize: `${lineHeight - 1}vmin`,
            textAlign: "left",
            overflow: "hidden",
            color: color
        }
    }

    private styleText(): React.CSSProperties {
        const lineHeight = this.props.cellSize / 5;
        return {
            display: "inline-block",
            verticalAlign: "middle",
            lineHeight: `${lineHeight}vmin`,
            fontSize: `${lineHeight - 1}vmin`,
            textAlign: "center",
            hyphens: "auto",
        }
    }

    private getCellBackgroundColor() {
        return this.props.task.category.backgroundColor ?? Constants.TILE_DEFAULT_BACKGROUND;
    }

    @autobind
    private toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    @autobind
    private toggleDone() {
        this.setState({done: !this.state.done})
    }

}