
export namespace BingoData {

    export interface Category {
        id: string,
        prefix: string,
        name: string;
        count?: number;
        backgroundColor?: string;
    }

    export interface Task {
        id: string,
        category: Category,
        name: string,
    }

    const TASK_NAMES_CATEGORY_MAP: Map<Category, string[]> = new Map([
        [{id: "FREE", prefix: "f", name: "Free", backgroundColor: "#ffffff"}, [
            "Free spot",
        ]],

        [{id: "COMPLETION", prefix: "c", name: "Completion" }, [
            "Smack that axe",
            "Bring Yoshi to the goal",
            "Trolled exit (kaizo, spikes, etc.)",
            "True ending found"
        ]],

        [{id: "ENEMIES", prefix: "e", name: "Enemies" }, [
            "Enemy spam",
            "Meowser",
            "Boom-boom",
            "Bowser",
            "Boss chamber",
            "Boss with wings",
            "Multiple Koopalings"
        ]],

        [{id: "LEVEL", prefix: "l", name: "Style" }, [
            "Auto mario",
            "Speedrun 50s or less",
            "Speedrun 20s or less",
            "Car level",
            "Underwater level",
            "Rising water level",
            "Auto-scroller",
            "Music level",
            "1-1 remake",
            "Themed after another game",
            "Puzzle level",
            "Trick level / Uno Mas",
            "Precision level",
            "Troll level",
            "Multiplayer level",
            "Dark level",
            "Very tight timer",
            "ON/OFF auto cycle",
            "4-Lane Multiplayer",
            "Leap of faith jump",
            "Straight to the goal",
            "Escort level",
            "Vertical level"
        ]],

        [{id: "SITUATION", prefix: "s", name: "Situations" }, [
            "Softlock with no way of dying",
            "Died to kaizo block",
            "Cheese",
            "Mario dies at the start",
            "Dev exit",
            "Dev star",
        ]],

        [{id: "ELEMENTS", prefix: "m", name: "Elements" }, [
            "Poison mushroom",
            "Zelda powerup",
            "Streamer name inside level",
            "GG or THX at the end",
            "Contraption / pit after level completion",
            "Pick a door / pipe",
            "Troll pipe / door",
            "Checkpoints available",
            "No checkpoints (no clear condition)",
            "Clear condition",
            "Sound effects everywhere",
            "SMB2 mushroom",
            "Forced waiting",
            "Art / picture",
            "Naked pipe",
            "Mandatory Yoshi kill",
            "Snake block",
            "Swimming with Dry Bones",
            "Frog / Acorn / Cape",
            "P-Balloon",
            "BIG Mario powerup",
        ]],

        [{id: "TITLE", prefix: "t", name: "Titles" }, [
            "\"My first\" in title",
            "\"#Streamer \" in title",
            "Timer in title",
            "Title in foreign language",
            "Typo in title in English",
            "\"Speedrun\" but not really"
        ]],

        [{id: "RECORDS", prefix: "r", name: "Records" }, [
            "First clear",
            "World record time"
        ]],

        [{id: "WORLD", prefix: "w", name: "World Tour" }, [
            "Creator from North America",
            "Creator from Asia",
            "Creator from Europe",
            "Creator from South America",
            "Creator from Africa",
            "Creator from Oceania",
        ]],

        [{id: "OPINION", prefix: "o", name: "Opinions" }, [
            "Hot garbage level",
            "Classic style level",
            "Cool level idea",
            "Nice terrain detail",
        ]],

        [{id: "EXTRA", prefix: "x", name: "Challenges" }, [
            "No enemies killed",
            "Kill every possible enemy",
            "No damage (without clear condition)",
            "First try clear",
            "Top of the flagpole",
            "3 x 1UP acquired",
        ]],

    ]);

   export let CATEGORIES: Category[] = [];
   export let TASKS: Task[] = [];
   export let TASK_ID_MAP: Map<string, Task> = new Map();

   TASK_NAMES_CATEGORY_MAP.forEach((taskNames, category, map) => {
       category.count = taskNames.length;
       CATEGORIES.push(category);
       taskNames.forEach((name, index, array) => {
           const task: Task = {id: `${category.prefix}${index.toString(36).toUpperCase()}`, category, name};
           TASKS.push(task);
           TASK_ID_MAP.set(task.id, task);
       });
   });
   TASKS = TASKS.filter((task) => { return task.name && task.name.trim().length > 0 });
   console.log("Total tasks: ", TASKS.length);
}

