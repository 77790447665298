import { BingoData } from "../data";
import * as Color from 'color';
export class Constants {
    public static readonly BACKGROUND_COLOR = "#ffd302";
    // public static readonly BACKGROUND_COLOR = "#749262";
    public static readonly TILE_DEFAULT_BACKGROUND = new Color(Constants.BACKGROUND_COLOR).rotate(-7).lighten(0.66).toString();
    public static readonly SETTING_BACKGROUND = new Color(Constants.BACKGROUND_COLOR).lighten(0.5).toString();
    public static readonly SETTING_SELECTED_BACKGROUND = new Color(Constants.BACKGROUND_COLOR).rotate(-45).darken(0.33).toString();
    public static readonly SETTING_SELECTED_COLOR = "#ffffff";
    public static readonly SETTING_COLOR = new Color(Constants.SETTING_SELECTED_BACKGROUND).lighten(0.33).saturate(-0.66).toString();
    // public static readonly SETTING_COLOR = new Color(Constants.SETTING_BACKGROUND).darken(0.5).saturate(-0.5).toString();
    public static readonly SETTINGS_LABEL_COLOR = new Color(Constants.BACKGROUND_COLOR).rotate(-45).darken(0.8).toString();
}