import * as React from 'react';
import { autobind } from 'core-decorators';
import { BingoData } from '../data';
import { BingoCell } from './BingoCell';

export interface BingoGridProps {
    size: number;
    logoVisible: boolean;
    tasks: BingoData.Task[];
}

export interface BingoGridState {
}

export class BingoGrid extends React.Component<BingoGridProps, BingoGridState> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const gridSize = (this.props.logoVisible ? 90 : 100);
        const cellSize = gridSize / this.props.size;
        return <div style={this.styleGrid(gridSize)}>
            {this.props.tasks.map(task => <BingoCell cellSize={cellSize} task={task} key={task.id} />)}
        </div>
    }

    private styleGrid(gridSize: number): React.CSSProperties {
        const cellSizePercent = `${100 / this.props.size}% `;
        const gridTemplate = cellSizePercent.repeat(this.props.size);
        return {
            boxSizing: "border-box",
            marginLeft: "auto",
            marginRight: "auto",
            display: "grid",
            height: `${gridSize}vmin`,
            width: `${gridSize}vmin`,
            gridTemplateRows: gridTemplate,
            gridTemplateColumns: gridTemplate,
            borderTop: "solid 2px #000000",
            borderLeft: "solid 2px #000000"
        }
    }


}