import * as Color from 'color';
import { autobind } from 'core-decorators';
import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import { Config } from '../modules/config';
import { Constants } from '../modules/constants';
import { GridGenerator } from '../modules/generator';
import { BingoSettingsScreen, BingoSettingsScreenProps } from './BingoSettingsScreen';

enum BingoButtonType {
    Settings,
    Refresh
}

type BingoButtonHandler = (button: BingoButtonType) => void;

export interface BingoButtonsState {
    settingsVisible: boolean
}

export class BingoButton extends React.Component<{ onClick: () => void; icon: string }, { hover: boolean }> {

    constructor(props) {
        super(props);
        this.state = { hover: false};
    }

    render() {
        return  <div>
            <div style={this.styleIcon()} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} onClick={this.handleClick} ><FontAwesome name={this.props.icon} /></div>
        </div>

    }

    private styleIcon(): React.CSSProperties {
        const color = new Color(Constants.BACKGROUND_COLOR).darken(this.state.hover ? 0.66 : 0.33).toString();
        return {
            fontSize: "8vmin",
            color: color,
            cursor: this.state.hover ? "pointer" : "auto",
        }
    }

    @autobind
    private toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    @autobind
    private handleClick(e) {
        e.stopPropagation();
        this.props.onClick();
    }
}

export class BingoButtonsWrapper extends React.Component<{ handler: BingoButtonHandler }, { hidden: boolean }> {

    constructor(props) {
        super(props);
        this.state = { hidden: false };
    }

    render() {
       return <div style={this.styleWrapper()} onClick={() => this.props.handler(BingoButtonType.Settings)} onContextMenu={this.handleHide}>
            <BingoButton onClick={() => this.props.handler(BingoButtonType.Settings)} icon={"cog"} />
            <BingoButton onClick={() => this.props.handler(BingoButtonType.Refresh)} icon={"refresh"} />
        </div>
    }

    private styleWrapper(): React.CSSProperties {
        return {
            boxSizing: "border-box",
            position: "absolute",
            top: "2vmin",
            right: "2vmin",
            fontSize: "8vmin",
            opacity: this.state.hidden ? 0 : 1
        }
    }

    @autobind
    private handleHide(e) {
        e.preventDefault();
        this.setState({hidden: !this.state.hidden})
    }

}

export class BingoSettingsScreenWrapper extends React.Component<BingoSettingsScreenProps, null> {

    constructor(props) {
        super(props);
    }

    render() {
        return <div style={this.styleWrapper()} onClick={() => this.props.onClose()}>
            <BingoSettingsScreen {...this.props} />
        </div>
    }

    private styleWrapper(): React.CSSProperties {
        return {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.75)",
        }
    }

}

export class BingoButtons extends React.Component<{ onRefresh: () => void }, BingoButtonsState> {

    constructor(props) {
        super(props);
        this.state = { settingsVisible: false };
    }

    render() {
        return this.state.settingsVisible ? <BingoSettingsScreenWrapper onClose={this.onSettingsClosed} onRefresh={this.onSettingsRefreshCallback} onRegenerate={this.onSettingsRegenerateCallback} /> : <BingoButtonsWrapper handler={this.buttonPressed} />
    }

    @autobind
    private onSettingsClosed() {
        this.setState({ settingsVisible: false });
    }

    @autobind
    private onSettingsRefreshCallback() {
        this.props.onRefresh?.();
    }

    @autobind
    private onSettingsRegenerateCallback() {
        GridGenerator.generateTasks();
        this.props.onRefresh?.();
    }

    @autobind
    private buttonPressed(button: BingoButtonType) {
        if (button == BingoButtonType.Settings) {
            this.setState({ settingsVisible: !this.state.settingsVisible });
        } else if (button == BingoButtonType.Refresh) {
            GridGenerator.generateTasks();
            this.props.onRefresh?.();
        }
    }

}